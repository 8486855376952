import { PageConfig } from "../../router";
import { useParams } from "react-router-dom";
import PassportTable from "../../components/dashboard/user/PassportTable";
import { useEffect, useState } from "react";
import { Passport } from "../../interfaces/passport.interface";
import { getUserPassports } from "../../utils/rest-api/users";
import { useApp } from "../../contexts/AppContext";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - User's Passport Page",
  description: "Luna - User's Passport Page",
};

export default function ViewUserPassportPage() {
  const params = useParams();
  const userId = params.id;
  const [passports, setPassports] = useState<Passport[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    showLoader();
    getUserPassports(userId!, undefined, page, 10)
      .then((res: any) => {
        const total = res.data.totalPages;
        setTotalPages(total);
        const data = res.data.data;
        setPassports(data);
      })
      .finally(() => hideLoader());
  }, [userId, page]);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="mb-20">
              <span className="text-30 lh-14 fw-600">Saved Passport</span>
            </div>
            <PassportTable
              data={passports}
              className="h-100 md-overflow-y-visible overflow-y-scroll"
              total={totalPages}
              page={page}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const ViewUserPassportPageConfig: PageConfig = {
  path: "/users/:id/passports",
  component: ViewUserPassportPage,
  roles: ["admin", "root"],
};
