import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Nav } from "react-bootstrap";
import { Role, User } from "../../../interfaces/user.interface";
import {
  addBalance,
  addUsers,
  deductBalance,
  getUser,
  updateUsers,
} from "../../../utils/rest-api/users";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import React from "react";
import { useApp } from "../../../contexts/AppContext";
import {
  addSeperator,
  removeNonNumeric,
} from "../../../utils/currencyValidator";

export interface UserFormProps extends PropsWithChildren {
  className?: string;
  userId?: string;
}

// const roles = ["root", "admin", "user", "agent"];

const UserForm: FC<UserFormProps> = (props) => {
  const [user, setUser] = useState<
    User & { password?: string; passwordConfirmation?: string }
  >({
    _id: "",
    email: "",
    fullName: "",
    phone: "",
    createdAt: "",
    updatedAt: "",
    isVerified: true,
    balance: 0,
    role: undefined,
    password: "",
    passwordConfirmation: "",
    isActive: true,
  });

  const [invalidUserProps, setInvalidUserProps] = useState({
    email: "",
    fullName: "",
    phone: "",
    role: "",
    password: "",
    passwordConfirmation: "",
  });

  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    if (props.userId) {
      showLoader();
      getUser(props.userId)
        .then((res: any) => {
          setUser({
            ...(res.data.data as User),
            password: "",
            passwordConfirmation: "",
          });
        })
        .finally(() => hideLoader());
    }
  }, [props.userId]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.currentTarget.name]: e.currentTarget.value });
    setInvalidUserProps({ ...invalidUserProps, [e.currentTarget.name]: "" });
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUser({ ...user, [e.currentTarget.name]: e.currentTarget.value });
    setInvalidUserProps({ ...invalidUserProps, [e.currentTarget.name]: "" });
  };

  const onActiveSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, isActive: !user.isActive });
  };

  const navigate = useNavigate();
  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        setInvalidUserProps({
          ...invalidUserProps,
          ...err.response.data.message,
        });
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  const onSave = () => {
    if (props.userId) {
      const userData = {
        email: user.email,
        fullName: user.fullName,
        phone: user.phone,
        password: user.password,
        passwordConfirmation: user.passwordConfirmation,
        isActive: user.isActive,
      };
      if (!userData.password) {
        delete userData.password;
        delete userData.passwordConfirmation;
      }
      showLoader();
      updateUsers(props.userId, userData)
        .then((res) => {
          toast.success(
            `${user.role!.charAt(0).toUpperCase()}${user.role!.slice(1)} ${
              user.fullName
            } successfully updated`
          );
          navigate("/users");
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => hideLoader());
    } else {
      const userData = {
        email: user.email,
        fullName: user.fullName,
        phone: user.phone,
        role: user.role as Role,
        password: user.password!,
        passwordConfirmation: user.passwordConfirmation!,
        isActive: user.isActive,
      };
      showLoader();
      addUsers(userData)
        .then((res) => {
          toast.success(
            `${user.role!.charAt(0).toUpperCase()}${user.role!.slice(1)} ${
              user.fullName
            } successfully added`
          );
          navigate("/users");
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => hideLoader());
    }
  };

  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const onBalanceModalHide = () => {
    setShowBalanceModal(false);
  };

  const initialBalanceForm = {
    amount: "1000000",
    remarks: "",
    referenceId: "",
  };
  const [updateBalanceForm, setUpdateBalanceForm] = useState({
    ...initialBalanceForm,
  });

  const [showAddDeductConfirmationModal, setShowAddDeductConfimationModal] =
    useState(false);
  const [addOrDeductConfirmationLabel, setAddOrDeductConfirmationLabel] =
    useState<"add" | "deduct">("add");

  const showAddDeductConfirmation = (update: "add" | "deduct") => {
    setUpdateBalanceForm({
      ...updateBalanceForm,
      referenceId: crypto.randomUUID(),
    });
    setAddOrDeductConfirmationLabel(update);
    setShowBalanceModal(false);
    setShowAddDeductConfimationModal(true);
  };
  const hideAddDeductConfirmation = () => {
    setShowAddDeductConfimationModal(false);
    setShowBalanceModal(true);
  };

  const updateBalance = () => {
    if (
      isNaN(parseInt(updateBalanceForm.amount)) ||
      parseInt(updateBalanceForm.amount) < 0 ||
      updateBalanceForm.remarks.length <= 0
    )
      return;

    if (addOrDeductConfirmationLabel == "add") {
      showLoader();
      addBalance(
        props.userId!,
        parseInt(updateBalanceForm.amount),
        updateBalanceForm.referenceId,
        updateBalanceForm.remarks
      )
        .then((res) => {
          toast.success("Add balance success");
          setUpdateBalanceForm({ ...initialBalanceForm }); //reset form
          setShowAddDeductConfimationModal(false); //hide modal
          getUser(props.userId!).then((res: any) => {
            setUser({
              ...(res.data.data as User),
              password: "",
              passwordConfirmation: "",
            });
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => hideLoader());
    } else if (addOrDeductConfirmationLabel == "deduct") {
      showLoader();
      deductBalance(
        props.userId!,
        parseInt(updateBalanceForm.amount),
        updateBalanceForm.referenceId,
        updateBalanceForm.remarks
      )
        .then((res) => {
          toast.success("Deduct balance success");
          setUpdateBalanceForm({ ...initialBalanceForm }); //reset form
          setShowAddDeductConfimationModal(false); //hide modal
          getUser(props.userId!).then((res: any) => {
            setUser({
              ...(res.data.data as User),
              password: "",
              passwordConfirmation: "",
            });
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => hideLoader());
    }
  };

  return (
    <div className={`ps-2 pe-2 pb-30 ${props.className}`}>
      <div className="d-flex flex-row w-form-user mb-20">
        <span className="text-30 lh-14 fw-600">
          {props.userId ? "Edit" : "Add"} User
        </span>
        <div className="d-flex flex-grow-1"></div>
        {props.userId && ["agent", "user"].indexOf(user.role ?? "") >= 0 && (
          <React.Fragment>
            <Button
              className="me-2"
              variant="primary"
              onClick={() => navigate(`/users/${props.userId!}/passports`)}
            >
              Show Saved Passport
            </Button>
            <Button
              variant="primary"
              onClick={() => navigate(`/users/${props.userId!}/mutations`)}
            >
              Show Balance History
            </Button>
          </React.Fragment>
        )}
      </div>
      <div className="text-15 text-light-1">
        <div className={`d-flex flex-column ${props.className}`}>
          <Form className="w-form-user" onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-2">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                className={invalidUserProps.fullName && `border-error-2`}
                type="text"
                name="fullName"
                value={user.fullName}
                onChange={onInputChange}
              />
              <Form.Text className={`text-error-2`}>
                {invalidUserProps.fullName}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className={invalidUserProps.fullName && `border-error-2`}
                type="email"
                name="email"
                value={user.email}
                onChange={onInputChange}
              />
              <Form.Text className={`text-error-2`}>
                {invalidUserProps.email}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                className={invalidUserProps.phone && `border-error-2`}
                type="tel"
                name="phone"
                value={user.phone}
                onChange={onInputChange}
              />
              <Form.Text className={`text-error-2`}>
                {invalidUserProps.email}
              </Form.Text>
            </Form.Group>
            <InputGroup className="mb-2 d-flex flex-row gap-2">
              <Form.Group className="flex-grow-1">
                <Form.Label>Role</Form.Label>
                <Form.Select
                  className={invalidUserProps.role && `border-error-2`}
                  disabled={props.userId !== undefined}
                  name="role"
                  aria-label="Role"
                  value={user.role}
                  onChange={onSelectChange}
                >
                  <option value={""} hidden></option>
                  <option value="root" hidden disabled>
                    Root
                  </option>
                  <option value="user" hidden disabled>
                    User
                  </option>
                  <option value="admin">Admin</option>
                  <option value="agent">Agent</option>
                </Form.Select>
                <Form.Text className={`text-error-2`}>
                  {invalidUserProps.role}
                </Form.Text>
              </Form.Group>
              <Form.Group className="flex-grow-1">
                <Form.Label>Balance (Rp)</Form.Label>
                <div className="flex-row d-flex gap-2">
                  <Form.Control
                    readOnly
                    disabled
                    type="text"
                    name="balance"
                    value={addSeperator(user.balance.toString())}
                  />
                  {props.userId && user.role == "agent" && (
                    <Button
                      variant="primary"
                      onClick={(e) => setShowBalanceModal(true)}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </Form.Group>
            </InputGroup>
            <InputGroup className="mb-40 d-flex flex-row gap-2">
              <Form.Group
                className="mb-2"
                style={{ width: "calc(100% / 2 - 5px)" }}
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  autoComplete="new-password"
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={onInputChange}
                />
                <Form.Text className={`text-error-2`}>
                  {invalidUserProps.password}
                </Form.Text>
              </Form.Group>
              <Form.Group
                className="mb-2"
                style={{ width: "calc(100% / 2 - 5px)" }}
              >
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control
                  autoComplete="new-password"
                  type="password"
                  name="passwordConfirmation"
                  value={user.passwordConfirmation}
                  onChange={onInputChange}
                />
                <Form.Text className={`text-error-2`}>
                  {invalidUserProps.passwordConfirmation}
                </Form.Text>
              </Form.Group>
              {props.userId && (
                <Form.Group className="mb-40">
                  <Form.Check
                    type="switch"
                    label="Active"
                    name="isActive"
                    checked={user.isActive}
                    onChange={onActiveSwitchChange}
                  />
                </Form.Group>
              )}
            </InputGroup>
            <Button onClick={onSave} variant="primary" className="w-100">
              Save
            </Button>
          </Form>
          <Modal
            contentClassName="w-user-balance-modal"
            className="d-flex justify-center items-center"
            show={showBalanceModal}
            onHide={onBalanceModalHide}
          >
            <Modal.Header closeButton className="ms-4 me-4">
              <Modal.Title>Update Balance</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column">
              <span className="mb-3">
                Add / Deduct {user.fullName}'(s) balance
              </span>
              <Form.Group className="mb-3 flex-grow-1">
                <Form.Label>Amount (Rp)</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={addSeperator(updateBalanceForm.amount)}
                  onChange={(e) => {
                    const IntValue = parseInt(
                      removeNonNumeric(e.currentTarget.value)
                    );
                    const newValue = IntValue ? IntValue : 0;
                    setUpdateBalanceForm({
                      ...updateBalanceForm,
                      amount: newValue.toString(),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 flex-grow-1">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="remarks"
                  value={updateBalanceForm.remarks}
                  onChange={(e) =>
                    setUpdateBalanceForm({
                      ...updateBalanceForm,
                      remarks: e.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary flex-grow-1"
                onClick={(e) => showAddDeductConfirmation("add")}
                disabled={updateBalanceForm.remarks.length < 10}
              >
                Add
              </Button>
              <Button
                variant="primary flex-grow-1"
                onClick={(e) => showAddDeductConfirmation("deduct")}
                disabled={updateBalanceForm.remarks.length < 10}
              >
                Deduct
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showAddDeductConfirmationModal}
            onHide={hideAddDeductConfirmation}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are sure want to {addOrDeductConfirmationLabel.charAt(0)}
                {addOrDeductConfirmationLabel.slice(1)} {user.fullName}'(s)
                balance
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={(e) => hideAddDeductConfirmation()}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => updateBalance()}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
