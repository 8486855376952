import { FC, PropsWithChildren } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import dayjs from "dayjs";
import { Passport } from "../../../interfaces/passport.interface";

export interface PassportTableProps extends PropsWithChildren {
  className: string;
  page: number;
  total: number;
  data: Array<Passport>;
  onPageChange: (page: number) => void;
}

const PassportTable: FC<PassportTableProps> = (props) => {
  return (
    <div className={props.className || ""}>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Fullname</th>
            <th>Number</th>
            <th>Date Of Birth</th>
            <th>Issuing Country</th>
            <th>Nationality</th>
            <th>Gender</th>
            <th>Expired Date</th>
            <th>Issuing Date</th>
          </tr>
        </thead>
        <tbody>
          {props.data && props.data.length > 0 ? (
            props.data.map((dt, i) => {
              return (
                <tr key={`user-${i}`}>
                  <td>{dt.fullName}</td>
                  <td>{dt.passportNumber}</td>
                  <td>{dt.dateOfBirth}</td>
                  <td>{dt.issuingCountry}</td>
                  <td>{dt.nationality}</td>
                  <td>
                    {dt.gender.charAt(0).toUpperCase() + dt.gender.slice(1)}
                  </td>
                  <td>{dayjs(dt.expiredDate).format("DD-MMM-YYYY")}</td>
                  <td>{dayjs(dt.issuingDate).format("DD-MMM-YYYY")}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="text-center" colSpan={5}>
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination
        page={props.page}
        totalPage={props.total}
        onPageClick={(page) => props.onPageChange(page)}
      />
    </div>
  );
};

export default PassportTable;
